import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Container from '../components/Container';
import PageTitle from '../components/PageTitle';
import ImageRow from '../components/ImageRow';
import BookNowButton from '../components/BookNowButton';
import { Services, Service } from '../components/Services';

import servicesData from '../services';

export default function ServicesPage() {
    return (
        <>
            <title>BARBA | Choose your barbershop service</title>
            <meta
                name="description"
                content="BARBA offers a range of barbershop services – hair cuts, beard trims, cut throat shaves, facials, products and more – book now"
            />
            <Header isDark />
            <PageTitle title="Services" />
            <Container narrow classes="u-pt--xlarge u-pb--xlarge u-ta--center">
                <Services>
                    {servicesData.map((service) => (
                        <Service key={service.title} {...service} />
                    ))}
                </Services>
                <BookNowButton classes="u-mt--xlarge" size="large">
                    Book Now
                </BookNowButton>
            </Container>
            <ImageRow url="/dan-rob-cutting.jpg" />
            <Footer />
        </>
    );
}
