import React from 'react';
import PropTypes from 'prop-types';

const ImageRow = (props) => {
    const { url } = props;

    return (
        <section
            className="c-image-row"
            style={{ backgroundImage: `url(${url})` }}
        />
    );
};

ImageRow.propTypes = {
    url: PropTypes.string.isRequired,
};

export default ImageRow;
