import React from 'react';
import PropTypes from 'prop-types';

export const Services = (props) => (
    <section className="c-services">{props.children}</section>
);

export const Service = (props) => {
    const { title, description } = props;

    return (
        <div className="c-services__item">
            <span className="c-services__title">{title}</span>
            <span className="c-services__desc">{description}</span>
        </div>
    );
};

Services.propTypes = {
    children: PropTypes.any.isRequired,
};

Service.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    link: PropTypes.string,
    translation: PropTypes.string,
};
