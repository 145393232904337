export default [
    {
        title: 'Signature haircut and finish',
        description:
            'Signature haircut and finish by our BARBAs. We believe our signature service is all about that. The service. Care and attention to detail are the core fundamentals of BARBA.',
    },
    {
        title: 'Beard trim',
        description:
            'The word "barber" is derived from the latin word "BARBA" meaning beard. Outlined on the neck with a hot towel cutthroat treatment. Our BARBAs are dedicated to creating the beard of your dreams.',
    },
    {
        title: 'Facial',
        description:
            'Allow the essence and history of the BARBA to seep through and cleanse your skin. Let your chosen BARBA take you on a journey through time with this hot towel experience.',
    },
    {
        title: 'The full experience. Haircut, beard service and facial',
        description:
            'A very exclusive club. Highest level of service, combining our other core services, a haircut, beard service and a facial. the truest BARBA form.',
    },
    {
        title: 'Cut throat shave',
        description:
            'Full cut throat shave, including hot towel experience. Our BARBAs are skilled bladesmen. Leave this to them.',
    },
    {
        title: 'Hair wash',
        description: 'Hair wash, scalp cleanse, head massage.',
    },
];
